@import "../../common/mixins.scss";
@import "../../common/typography.scss";

.linkedin {
  @include for-phone-only {
    display: none;
  }

  @include for-desktop-up {}
}

.header-home {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: space-around;
  background-color: #331e9a00;
  height: 84px;
  position: relative;
  z-index: 10;
}

.header {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: space-around;
  background-color: #331e9a00;
  height: 84px;
  position: relative;
  z-index: 10;
  background-image: linear-gradient(104.62deg, #3A0067 0%, #214FF3 98.48%);
}

main {
  @include for-phone-only {
    padding-left: 15px;
    padding-right: 15px;
  }

  @include for-desktop-up {
    padding-top: 30px;
    padding-left: 215px;
    padding-right: 215px;
  }
}

.see1 {
  background-color: #00000000;
  border-width: 1px;
  border-style: dashed;
}