.standard-button {
    padding: 10px 30px;
    min-width: 150px;
    height: 50px;
    background: #495CFE;
    border-radius: 30px;
    border: solid 0px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
}

.standard-button:hover {
    background: #6171fe;
}