@import "../../common/mixins.scss";

nav {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    @include for-phone-only {
        gap: 0;
    }

    a {
        font-family: 'Rubik';
        color: white;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        text-decoration: none;
        border-radius: 30px;

        @include for-phone-only {
            padding-top: 5px;
            padding-bottom: 5px;
            padding-left: 10px;
            padding-right: 10px;
        }

        @include for-tablet-portrait-up {
            margin: 10px;
            padding-top: 8px;
            padding-bottom: 8px;
            padding-left: 10px;
            padding-right: 10px;
        }

        @include for-tablet-landscape-up {
            padding-top: 14px;
            padding-bottom: 14px;
            padding-left: 30px;
            padding-right: 30px;
        }

        @include for-desktop-up {
            padding-top: 14px;
            padding-bottom: 14px;
            padding-left: 30px;
            padding-right: 30px;
        }
    }

    a:hover {
        background: rgba(255, 255, 255, 0.4);
    }

    .active {
        background: rgba(255, 255, 255, 0.4);
    }

}