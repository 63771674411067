@import "../../common/mixins.scss";

footer {
    background-image: url("./img/footer-image.svg");
    background-size: cover;
    color: white;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-around;
    height: 550px;

    @include for-phone-only {
        margin-top: 50px;
    }

    @include for-desktop-up {
        margin-top: 100px;
        padding: 60px 140px 0px 140px;
    }

    .footer-info {
        display: flex;
        flex-direction: column;

        @include for-phone-only {
            margin-top: 70px;
        }

        @include for-desktop-up {
            margin-top: 20px;
        }
    }

    .footer-text {
        max-width: 305px;
        line-height: 2;

        @include for-phone-only {
            text-align: center;
        }

        @include for-desktop-up {
            margin-top: 40px;
        }
    }

    .hr-center {
        display: flex;
        justify-content: left;
        width: 100%;

        hr {
            width: 50px;
            height: 2px;
            background-color: white;
            margin: -20px 0px 30px 0px;
        }
    }

    .footer-link:hover {
        text-decoration: underline;
    }

    .footer-menu {
        @include for-phone-only {
            display: none;
            margin: 0;
        }

        margin: 0 20px 0 20px;

        .footer-menu-title {
            text-align: left;
            font-size: 28px;
            font-weight: 800;
            line-height: 38px;
            color: white;

            @include for-phone-only {
                text-align: center;
            }
        }
    }

    #contact-us {
        .contact-buttons {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        @include for-phone-only {
            display: block;

            #contact-us-line {
                display: none;
            }

            .contact-buttons {
                flex-direction: row;
                justify-content: center;

                @include for-phone-only {
                    width: 250px;
                }

                button {
                    font-size: medium;
                    line-height: 1.1;
                    min-width: 63%;
                }
            }
        }
    }

    #copyright-line {
        margin-top: 30px;

        @include for-phone-only {
            hr {
                opacity: 30%;
                margin-top: 5px;
                min-width: 90%;
            }
        }
    }

    .copyright {
        display: inline-block;
        white-space: nowrap;
        margin-bottom: 25px;
    }
}