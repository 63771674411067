@import "../../common/mixins.scss";

.package-box {
    display: flex;
    flex-direction: column;
    align-content: stretch;
    justify-items: center;
    align-items: center;
    gap: 20px;

    @include for-desktop-up {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 50px;
        justify-content: center;
    }
}

.roadmap-title {
    @include for-desktop-up {
        margin-top: 60px;
    }

}

.roadmap-work-process {
    @include for-phone-only {
        text-align: center;
    }
}

.roadmap-box {
    display: flex;

    @include for-phone-only {
        flex-direction: column;
    }

    @include for-desktop-up {
        flex-direction: row;
    }

    .roadmap-planning {
        @include for-desktop-up {
            flex-direction: row;
            width: 800px;
            margin-left: -100px;
        }
    }

    .roadmap-planning-steps {
        display: flex;
        flex-direction: column;
        width: 100%;

        @include for-desktop-up {
            margin-left: 40px;
        }

        button {
            width: 1fr;
            padding: 17px;
            margin: 10px;
            background: #FFFFFF;
            box-shadow: 0px 0px 10px 1px #EFF3F9;
            border-radius: 10px;
            border: none;

            font-family: 'Nunito';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
        }
    }
}