@import "../../common/mixins.scss";

@keyframes move_wave {
    0% {
        transform: translateX(0) translateZ(0) scaleY(1)
    }

    50% {
        transform: translateX(-25%) translateZ(0) scaleY(0.55)
    }

    100% {
        transform: translateX(-50%) translateZ(0) scaleY(1)
    }
}

.introduction-content {
    display: grid;
    grid-template-areas:
        "introduction-text ."
        "buttons image";
    position: relative;
    z-index: 10;
    justify-content: space-around;

    @include for-phone-only {
        grid-template-areas:
            "introduction-text"
            "image"
            "buttons";

    }

    .introduction-text {
        grid-area: introduction-text;
        z-index: 10;
        max-width: 600px;

        .introduction {
            color: white;
            margin-top: 10px;
            margin-bottom: 15px;

            @include for-phone-only {
                margin: 0 20px;
            }
        }

        .main-text {
            font-weight: 800;
            font-size: 70px;
            line-height: 95px;
            margin-bottom: -10px;

            @include for-phone-only {
                font-size: 30px;

            }
        }

        .second-text {
            font-weight: 800;
            font-size: 30px;

            @include for-phone-only {
                color: #2E2AAF;
                font-size: 20px;
                background-color: white;
                border-radius: 5px;
                flex-shrink: 0;
                text-align: center;
                width: 190px;
                margin-top: -20px;
                padding: 5px;
            }
        }
    }

    .buttons {
        grid-area: buttons;
        display: flex;
        flex-direction: row;
        vertical-align: middle;
        margin-top: 30px;
        gap: 40px;

        @include for-phone-only {
            margin-top: -110px;
            font-size: 18px;
            gap: 0px;
        }

        .button {
            // margin: 40px 60px 0px 0px;
            width: 206px;
            height: 50px;
            background: #495bfe00;
            border-radius: 30px;
            border: solid 0px;
            font-family: Rubik;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            /* identical to box height */
            display: flex;
            flex-direction: row;
            text-align: center;
            align-items: center;
            color: #FFFFFF;

            @include for-phone-only {
                font-size: 18px;
                line-height: normal;
            }
        }

        .play-button {
            width: 50px;
            margin-right: 20px;
        }
    }

    .image {
        grid-area: image;
        width: 600px;
        height: 600px;
        margin-top: -390px;

        @include for-phone-only {
            margin-top: -100px;
            width: 100%;
        }
    }
}

.wave-animation {
    overflow: hidden;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: -1px;
    z-index: 1;
    height: 850px;

    .wave-animation-inner {
        position: absolute;
        width: 100%;
        overflow: hidden;
        height: 110%;
        bottom: -1px;
        background-image: linear-gradient(104.62deg, #3A0067 0%, #214FF3 98.48%);
        margin-top: -50px;

        .wave {
            position: absolute;
            left: 0;
            width: 200%;
            height: 100%;
            background-repeat: repeat no-repeat;
            background-position: 0 bottom;
            transform-origin: center bottom;
        }
    }

    .bg-top {
        z-index: 15;
        opacity: 0.5;

        .wave-top {
            background-size: 50% 100px;
            animation: move-wave 3s;
            -webkit-animation: move-wave 3s;
            animation: move_wave 10s linear infinite;
            /* when one wave static
            -webkit-animation-delay: 1s;
            animation-delay: 1s;
            */

            @include for-phone-only {
                background-size: 50% 30px;
            }
        }
    }

    .bg-middle {
        z-index: 10;
        opacity: 0.75;

        .wave-middle {
            background-size: 50% 120px;
            animation: move_wave 10s linear infinite;

            @include for-phone-only {
                background-size: 50% 40px;
            }
        }
    }

    .bg-bottom {
        z-index: 5;

        .wave-bottom {
            background-size: 50% 100px;
            animation: move_wave 15s linear infinite;

            @include for-phone-only {
                background-size: 50% 40px;
            }
        }
    }

}