@import "../../common/mixins.scss";

.get-service {
    @include for-desktop-up {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.qa_process {
    display: grid;
    align-items: center;

    @include for-phone-only {
        img {
            max-width: 100%;
        }
    }

    @include for-desktop-up {
        grid-template-areas:
            "h4 h4 img"
            "p p img"
            "p p img";
        gap: 20px;
        margin: 50px 0px;

        h4 {
            grid-area: h4;
            margin-bottom: -40px;
            // align-self: i;
        }

        p {
            grid-area: p;
            // margin-top: -40px;
        }

        img {
            grid-area: img;
            margin-left: 70px;
        }
    }

}

.expenses {
    display: grid;
    align-items: center;

    @include for-phone-only {
        img {
            max-width: 100%;
        }
    }

    @include for-desktop-up {
        grid-template-areas:
            "img h4 h4"
            "img p p"
            "img p p";
        gap: 40px;
        margin: 50px 0px;

        h4 {
            grid-area: h4;
            margin-bottom: 10px;
        }

        p {
            grid-area: p;
            margin-top: -40px;
        }

        img {
            grid-area: img;
            margin-right: 70px;
        }
    }
}

.case-study-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.box {
    margin: 10px;
    background: #FFFFFF;
    border: 3px solid #A8C3FF;
    border-radius: 10px;
    color: #626161;

    font-weight: 400;
    font-size: 18px;
    line-height: 160%;

    @include for-phone-only {
        padding: 20px 20px 20px 20px;
    }

    @include for-desktop-up {
        width: 875px;
        padding: 20px 50px 20px 50px;
    }
}