@import "../../common/mixins.scss";

.content {
    margin-top: 220px;
    align-content: center;
    text-align: center;
    display: block;

    @include for-phone-only {
        margin-top: 20px;
    }

    .sub-title {
        font-family: 'Rubik';
        font-size: 20px;
        line-height: 24px;
        justify-content: center;

        @include for-phone-only {
            margin: 0px 10px;
        }

        @include for-desktop-up {
            margin: 0px 100px;
        }
    }

    .quality {
        // display: grid;
        // grid-template-columns: 1fr 1fr;
        justify-items: center;
        align-items: center;
        text-align: left;

        @include for-phone-only {
            display: grid;
            grid-template-columns: 1fr;
            margin-top: 30px;
        }

        @include for-desktop-up {
            gap: 100px;
            margin-top: 60px;
        }

        .quality-services {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            // display: grid;
            // grid-template-columns: 1fr 1fr;
            // position: relative;
            isolation: isolate;
            flex-direction: row;
            padding: 0px;
            box-sizing: border-box;
            gap: 20px;
            color: #2C62B5;
            font-family: 'Nunito';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 27px;
            margin-bottom: 50px;

            @include for-phone-only {
                display: flex;
                flex-direction: column;
                margin-top: 30px;
            }

            .boxed {
                background: #E7F0FF;
                border: #0C0266;
                border-radius: 10px;
                padding: 40px;
                align-items: center;
                justify-content: center;
                text-align: center;
                display: flex;
                flex-direction: column;

                max-width: 150px;
                // min-width: 100px;
                height: 200px;

                .quality-image {
                    margin: 30px;
                    margin-top: 5px;
                    max-width: 100px;

                    @include for-phone-only {
                        width: 100px;
                    }

                    @include for-desktop-up {
                        width: 100px;
                    }
                }
            }
        }

        .quality-focus-text {

            border-radius: 20px;
            border: 1px solid #D3EAFD;
            background: #FFF;
            padding: 20px 50px;

            color: #0C0266;
            font-family: Rubik;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 176%;

            @include for-phone-only {
                color: #0C0266;
                font-family: Rubik;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 176%;

                padding: 20px 20px;
                /* 31.68px */
            }
        }

    }

    .question {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px;

        p {
            color: #8BC34A;
            text-align: center;
            font-family: Nunito;
            font-size: 23px;
            font-style: normal;
            font-weight: 800;
            line-height: 176%;
            margin-right: 20px;
        }

        img {
            max-width: 70px;
        }
    }



    .quality-text {
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        text-align: center;
        // line-height: 176%;
        /* or 35px */

        @include for-desktop-up {
            margin-bottom: 30px;

            margin: 0 50px;
        }
    }

    .services {
        display: flex;
        flex-direction: column;
        background-image: url("./img/wave-background.svg");
        padding-bottom: 117px;
        margin-top: 40px;
        align-items: center;
        justify-items: center;
        margin-left: calc(50% - 50vw);
        margin-right: calc(50% - 50vw);
        height: max-content;

        .quality-two {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .quality-services1 {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                position: relative;
                isolation: isolate;
                flex-direction: row;
                padding: 0px;
                box-sizing: border-box;
                gap: 100px;
                margin-bottom: 70px;

                @include for-phone-only {
                    grid-template-columns: 1fr;
                    gap: 30px;
                }

                .boxed-service {
                    display: flex;
                    flex-direction: column;
                    width: 248px;
                    height: 272px;
                    background: #FFFFFF;
                    box-shadow: 0px 4px 4px rgba(151, 71, 255, 0.1);
                    border-radius: 10px;
                    padding: 30px;
                    align-items: center;
                    justify-content: center;

                    font-weight: 700;
                    font-size: 20px;
                    line-height: 27px;
                    font-family: 'Nunito';
                    font-style: normal;
                    color: #0C0266;

                    .service-image {
                        width: 150px;
                        margin: 20px;
                    }
                }
            }
        }
    }

    // .button {
    //     margin-top: 40px;
    //     border: none;
    //     border-radius: 30px;
    //     color: rgb(255, 255, 255);
    //     padding: 16px 32px;
    //     text-align: center;
    //     text-decoration: none;
    //     display: inline-block;
    //     font-size: 16px;
    //     transition-duration: 0.4s;
    //     background-color: #0C0266;
    //     cursor: pointer;
    // }
    .about {
        .about-content {
            display: flex;
            justify-content: space-around;

            @include for-phone-only {
                flex-direction: column-reverse;
                align-items: normal;
                gap: 30px;
            }

            @include for-desktop-up {
                margin-top: 50px;
                align-items: center;
            }

            .video-presentation {
                @include for-phone-only {
                    height: 200px;
                }

                @include for-desktop-up {
                    width: 700px;
                    height: 400px;
                }

                justify-items: center;
                align-items: center;

                .react-player {
                    border-radius: 15px;
                    overflow: hidden;
                }
            }

            img {
                width: 250px;
            }

            h3 {
                font-weight: 800;
                font-size: 24px;
                line-height: 33px
            }
        }
    }
}