.card {
    $card-width: 300px;
    $card-padding: 14.5px;
    height: 100%;
    border: #333EDD 4px;
    border-style: solid;
    border-radius: 10px;

    .title {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 800;
        font-size: 20px;
        line-height: 27px;
        padding: $card-padding;
        color: white;
        width: $card-width;
        background: linear-gradient(92.41deg, #510EA9 2.82%, #2C42E4 100%);
        border-radius: 4px 4px 0px 0px;
        text-align: center;
    }

    .card-content {
        display: flex;
        flex-direction: column;
        width: $card-width;
        background: #FFFFFF;
        padding: $card-padding;
        align-items: center;
        justify-content: space-between;
        border-radius: 10px;

        .sub-title {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 260px;

            p {
                margin-top: 5px;
                margin-bottom: 5px;
            }

            button {
                width: 100%;
            }
        }
    }

}