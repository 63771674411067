// https: //github.com/TryKickoff/scss/blob/master/_typography.scss

h1 {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 52px;
    color: #0c0266;
}

h2 {
    font-family: 'Nunito';
    color: #8bc34a;
    font-style: normal;
    font-weight: 800;
    font-size: 26px;
    line-height: 27px;
}

h3 {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    font-size: 33px;
    display: flex;
    justify-content: center;
    color: #0C0266;
}

h4 {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    color: #0C0266;
}

p {
    font-family: 'Rubik';
    font-style: normal;
    font-size: 18px;
    font-weight: 400;
    line-height: 160%;
}